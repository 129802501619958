<template>
    <div> 
        <div v-if="!this.$route.path.includes('property') && !this.$route.path.includes('commercial')" class="fixed-tags" style="background-color: #e00233; padding: 10px; position: fixed; bottom: 10px; right: 10px; z-index: 1000;">
            <a style="color: #fff;" href="https://wa.me/27832581212" target="_blank">
                <i class="bi bi-whatsapp"></i> &nbsp;<strong>GET IN TOUCH</strong>
            </a>
        </div>
        <nav class="navbar fixed-top navbar-expand-xl bg-body-white">
            <div class="container-fluid">
                <a class="navbar-brand me-0 p-2 pb-3" href="/"><img src="/images/logos/ce-logo.png" style="width: 180px;" /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse text-end pt-2 justify-content-end" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" href="/winelands">The Winelands</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Portfolio
                            </a>
                            <ul class="dropdown-menu">
                                <li><router-link to="/properties" @click.native="$store.state.searchState = 'Residential'; $store.state.filterType = 'Residential For Sale'; $store.state.filterProperty = 'House';" class="dropdown-item">Residential</router-link></li>
                                <li><router-link to="/estates" class="dropdown-item">Estates</router-link></li>
                                <li><router-link to="/developments" class="dropdown-item">New Developments</router-link></li>
                                <li><router-link to="/properties" @click.native="$store.state.searchState = 'Farms'; $store.state.filterType = 'Commercial For Sale'; $store.state.filterProperty = 'Farm';" class="dropdown-item">Farms & Smallholdings</router-link></li>
                                <li><router-link to="/properties" @click.native="$store.state.searchState = 'Commercial'; $store.state.filterType = 'Commercial For Sale'; $store.state.filterProperty = 'Office';" class="dropdown-item">Commercial & Industrial</router-link></li>
                                <li><a class="dropdown-item" href="/global-connect">Residency By Investment</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                About us
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/about">About Us</a></li>
                                <li><a class="dropdown-item" href="/team">Our Agents</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/news">Community News</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/home#footer">Enquire Now</a>
                        </li>
                        <li class="nav-item d-none d-md-block">
                            <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#newsletterModal"><span style="background-color: #e00233; color: #fff; padding: 8px;">Subscribe to our newsletter</span></a>
                        </li>
                        <li class="nav-item pt-2 d-none d-md-block">
                            <a href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank">
                                <i class="bi bi-facebook"></i>
                            </a>
                        </li>
                        <li class="nav-item pt-2 d-none d-md-block">
                            <a href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank">
                                <i class="bi bi-instagram"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="container-fluid d-block d-sm-none pt-3">
                        <div class="row">
                            <div class="col-md-12">
                                <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#newsletterModal"><span style="background-color: #e00233; color: #fff; padding: 8px;">Subscribe to our newsletter</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid d-block d-sm-none pt-3">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <a href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank" style="background-color: #103162; color: #fff; padding: 5px; border-radius: 25px;">
                                    <i class="bi bi-facebook"></i>
                                </a>
                                <a href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank" style="background-color: #103162; color: #fff; padding: 5px; border-radius: 25px;">
                                    <i class="bi bi-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="mt-5"></div>
    </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Search from '@/components/Search.vue'
import FeaturedProperty from '@/components/FeaturedProperty.vue'

export default {
  components: {
    Menu, Search, FeaturedProperty
  },
  mounted() {
    console.log(this.$route.path.includes('property'))
    console.log(this.$route.path.includes('commercial'))
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {
    toggleType(type) {
      if (type === 'buy' || type === 'rent') {
        this.$store.commit('setFilter', { filter: 'buy', value: type === 'buy' });
        this.$store.commit('setFilter', { filter: 'rent', value: type === 'rent' });
        this.$store.commit('setMaxPrice', this.maxPrice);
        this.selectedType = this.selectedType === type ? null : type;
      }
      console.log(this.$store.state.search)
    },
    toggleLocation(location) {
      console.log(location)
    },
  }
}
</script>
<style scoped>
    ul {
        list-style-type: none;
    }
    a {
        color: #fff;
        text-decoration: none;
    }
    a:hover {
        color: #fff;
        text-decoration: none;
        font-weight: bold;
    }
    .burger-menu {
        width: 30px;
        height: 20px;
        position: relative;
        cursor: pointer;
    }

    .bar {
        width: 100%;
        height: 4px;
        background-color: #333;
        margin: 6px 0;
        transition: 0.3s;
    }

    .burger-menu:hover .bar:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
    }

    .burger-menu:hover .bar:nth-child(2) {
        opacity: 0;
    }

    .burger-menu:hover .bar:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
    }
</style>