<template>
  <div>
    <div class="container mb-5">
      <div class="row">
        <form id="agentForm" class="formStyle" @submit.prevent="submitForm">
          <div class="col-md-12 ps-0">
            <div class="banner-section agentForm pb-0 ps-0">
              <input type="text" class="form-control mb-2" v-model="data.name" placeholder="YOUR NAME AND SURNAME" id="name">
              <span class="error-message">{{ data.errors.name }}</span>
              <input type="email" class="form-control mb-2" v-model="data.email" placeholder="YOUR EMAIL" id="email">
              <span class="error-message">{{ data.errors.email }}</span>
            </div>
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="form-check mb-2">
                  <input type="checkbox" class="form-check-input" v-model="data.acceptTerms" id="acceptTerms">
                  <label class="form-check-label" for="acceptTerms">I have read and accept the <a target="_blank" href="https://www.chaseveritt.co.za/disclaimer-policy-website/" class="switch-blue">terms and conditions</a></label>
                </div>
              </div>
              <button type="submit" class="btn cta-btn" :disabled="!data.acceptTerms">SEND</button>
              <div class="success-message pb-2" v-if="successMessage">{{ successMessage }}</div>
              <div class="error-message pb-2" v-if="errorMessage">{{ errorMessage }}</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
      data: {
        name: "",
        email: "",
        phone: "",
        message: "",
        receiveMarketing: false,
        acceptTerms: false,
        errors: {
          name: '',
          email: '',
          phone: '',
          message: '',
        },
      },
      successMessage: '',
      errorMessage: ''
    }
  },
  methods: {
    submitForm() {
      this.data.errors = {};
      this.successMessage = '';
      this.errorMessage = ''; 

      if (!this.data.name) {
        this.data.errors.name = "Name is required";
      }

      if (!this.data.email) {
        this.data.errors.email = "Email is required";
      }

      if (Object.keys(this.data.errors).length === 0) {
        axios.post("https://stratitude.co.za/api/agentForm.php", {
          name: this.data.name,
          email: this.data.email,
          phone: this.data.phone,
          message: "This is a newsletter subscription",
          receiveMarketing: this.data.receiveMarketing
        })
          .then(response => {
            this.successMessage = "You have been added to our newsletter distribution list.";
            this.data.name = "";
            this.data.email = "";
            this.data.phone = "";
            this.data.message = "";
            this.data.acceptTerms = false;
            this.data.receiveMarketing = false;
          })
          .catch(error => {
            this.errorMessage = "Email sending failed.";
          });
      }
    },
  },
}

</script>

