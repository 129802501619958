<template>
  <div>
    <div class="container mb-5">
      <div class="row">
        <form id="agentForm" class="formStyle" @submit.prevent="submitForm">
          <div class="col-md-12 ps-0">
            <div class="banner-section agentForm pb-0 ps-0">
              <input type="text" class="form-control mb-2" v-model="data.name" placeholder="YOUR NAME" id="name">
              <span class="error-message">{{ data.errors.name }}</span>
              <input type="tel" class="form-control mb-2" v-model="data.phone" placeholder="YOUR PHONE" id="phone">
              <span class="error-message">{{ data.errors.phone }}</span>
              <input type="email" class="form-control mb-2" v-model="data.email" placeholder="YOUR EMAIL" id="email">
              <span class="error-message">{{ data.errors.email }}</span>
            </div>
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="form-check mb-2">
                  <input type="checkbox" class="form-check-input" v-model="data.acceptTerms" id="acceptTerms">
                  <label class="form-check-label" for="acceptTerms">I have read and accept the <a target="_blank" href="https://www.chaseveritt.co.za/disclaimer-policy-website/" class="switch-blue">terms and conditions</a></label>
                </div>
                <div class="form-check mb-2">
                  <input type="checkbox" class="form-check-input" v-model="data.receiveMarketing" id="receiveMarketing">
                  <label class="form-check-label" for="receiveMarketing">I want to receive marketing material</label>
                </div>
              </div>
              <button type="submit" class="btn btn-secondary" :disabled="!data.acceptTerms">SEND</button>
              <div class="success-message pb-2" v-if="successMessage">{{ successMessage }}</div>
              <div class="error-message pb-2" v-if="errorMessage">{{ errorMessage }}</div>
              <div v-if="zipAvailable" class="mt-3">
                <a :href="zipLink" class="btn btn-success" download>Download Brochures</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      data: {
        name: "",
        email: "",
        phone: "",
        message: "",
        receiveMarketing: false,
        acceptTerms: false,
        errors: {
          name: '',
          email: '',
          phone: '',
          message: '',
        },
      },
      successMessage: '',
      errorMessage: '',
      zipAvailable: false, // New state to show the download button for the ZIP
      zipLink: '/files/buyers-pack.zip', // Path to the ZIP file
    }
  },
  methods: {
    submitForm() {
      this.data.errors = {};
      this.successMessage = '';
      this.errorMessage = ''; 

      // Validate form inputs
      if (!this.data.name) {
        this.data.errors.name = "Name is required";
      }

      if (!this.data.email) {
        this.data.errors.email = "Email is required";
      }

      if (!this.data.phone) {
        this.data.errors.phone = "Phone is required";
      } else if (!/^\d+$/.test(this.data.phone)) {
        this.data.errors.phone = "Phone must contain only numeric characters";
      }

      // If no errors, submit form
      if (Object.keys(this.data.errors).length === 0) {
        axios.post("https://stratitude.co.za/api/agentForm.php", {
          name: this.data.name,
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          receiveMarketing: this.data.receiveMarketing
        })
          .then(response => {
            this.successMessage = "Email has been sent and an agent will be in contact with you soon.";
            
            // Clear form fields
            this.data.name = "";
            this.data.email = "";
            this.data.phone = "";
            this.data.message = "";
            this.data.acceptTerms = false;
            this.data.receiveMarketing = false;
            
            // Make the ZIP download available
            this.zipAvailable = true;

            // Trigger the automatic ZIP download
            this.downloadZIP();
          })
          .catch(error => {
            this.errorMessage = "Email sending failed.";
          });
      }
    },

    // Method to handle ZIP file download
    downloadZIP() {
      const link = document.createElement('a');
      link.href = this.zipLink; // Use the actual ZIP file URL
      link.setAttribute('download', 'Buyers Pack.zip'); // Set the file name for the ZIP
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Remove the anchor after clicking
    }
  }
}
</script>

