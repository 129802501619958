<template>
  <div class="container pb-5 pt-5">
    <div class="search-filter">
      <center>
        <p class="pb-3"><strong>Find a property</strong></p>
        <router-link class="btn btn-primary filter-btn" to="/properties" @click.native="$store.state.filterType = 'Residential For Sale'; $store.state.searchState = 'All';">Residential For Sale</router-link>
        <router-link class="btn btn-primary filter-btn" to="/properties" @click.native="$store.state.filterType = 'Residential To Let'; $store.state.searchState = 'Rentals';">Residential To Rent</router-link>
        <router-link class="btn btn-primary filter-btn" to="/properties" @click.native="$store.state.filterType = 'Residential For Sale'; $store.state.filterProperty = 'Vacant Land'; $store.state.searchState = 'All'; ">Vacant Land</router-link>
        <router-link class="btn btn-primary filter-btn" to="/developments" @click.native="$store.state.searchState = 'Developments'">New Developments</router-link>
        <router-link class="btn btn-primary filter-btn" to="/properties" @click.native="$store.state.searchState = 'All'">Search our portfolio</router-link>
      </center>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  methods: {   
  },
}
</script>

